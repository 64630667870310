/*
 * Created by Stanton Francis on 12 September 2019.
 */

/**
 * A set of available modules in the application. These are used to determine
 * whether users are licenced to access some part of the system.
 */
const Module = {

  /**
   * A module for any functionality that is part of the minimal viable product.
   */
  Base: {id: 'Base', label: 'Base', licensedPerProperty: false},

  /**
   * A module that encompasses all stock management features such as stores,
   * stock movement and stock analysis.
   */
  StockManagement: {
    id: 'StockManagement',
    label: 'Stock Management',
    licensedPerProperty: false
  },

  /**
   * A module that encompasses all stock management features that facilitate
   * moving stock between properties. This depends on {@link #StockManagement}.
   */
  GlobalStockManagement: {
    id: 'GlobalStockManagement',
    label: 'Global Stock Management',
    licensedPerProperty: true
  },

  /**
   * A module that encompasses all accounts receivable features.
   */
  Arm: {
    id: 'Arm',
    label: 'Accounts Receivable Module',
    licensedPerProperty: true
  },

  /**
   * The Arm Bundles module enables you to sell pre-packaged quantities of
   * products within the Account Receivable module.
   * This depends on {@link #Arm}.
   */
  ArmBundles: {
    id: 'ArmBundles',
    label: 'Accounts Receivable Bundles',
    licensedPerProperty: true
  },

  /**
   * A module that encompasses all stock management features for supplier
   * customers (or partners). This depends on {@link #StockManagement} and
   * {@link #GlobalStockManagement}.
   */
  SupplierStockManagement: {
    id: 'SupplierStockManagement',
    label: 'Supplier Stock Management',
    licensedPerProperty: true
  },

  /**
   * A module that features the ability to combine and divide stock products
   * into other stock products. This depends on {@link #StockManagement}.
   */
  StockManufacturing: {
    id: "StockManufacturing",
    label: 'Stock Manufacturing',
    licensedPerProperty: true
  },

  /**
   * A module that provides the ability to import stock take data from a
   * Sculpture spreadsheet. This depends on {@link #StockManagement}.
   */
  StockTakeSculptureImport: {
    id: "StockTakeSculptureImport",
    label: 'Stock Take Sculpture Import',
    licensedPerProperty: true
  },

  /**
   * A module that provides the ability to import stock take data from a
   * Stock scale. This depends on {@link #StockManagement}.
   */
  StockScales: {
    id: "StockScales",
    label: 'Stock Scales',
    licensedPerProperty: true
  },

  /**
   * A module that provides custom menu functionality for the POS, where
   * customers can alter the way in which their products are listed on the
   * mobile app.
   */
  CustomMenus: {
    id: 'CustomMenus',
    label: 'Custom Menus',
    licensedPerProperty: false
  },

  /**
   * A module that provides set menu functionality for the POS, where
   * customers can alter the way in which their products are listed on the
   * mobile app.
   */
  SetMenus: {id: 'SetMenus', label: 'Set Menus', licensedPerProperty: true},

  /**
   * A module that provides functionality related to POS orders and order
   * management, including the Order Manger and Bump Screen app modes.
   */
  PosOrders: {id: 'PosOrders', label: 'POS Orders', licensedPerProperty: true},

  /**
   * A module providing functionality related to Uber Eats integration, i.e.
   * automatic order capture, menu publishing, and service status management.
   */
  UberEatsIntegration: {
    id: 'UberEatsIntegration',
    label: 'Uber Eats Integration',
    licensedPerProperty: true
  },

  /**
   * A module providing functionality related to GL Export, i.e.
   * Mapping of GL Numbers or creating/editing/deleting GL Export Setups.
   */
  GLExport: {id: 'GLExport', label: 'GL Export', licensedPerProperty: true},

  /**
   * A module providing functionality to import Mmanual Sales
   */
  ManualSales: {
    id: 'ManualSales',
    label: 'Manual Sales',
    licensedPerProperty: true
  },

  /**
   * This module allows access to some of the AR module functionality as well
   * as allowing you to create a custom payment that can perform profile lookups
   */
  InvoiceToGeneralLedger: {
    id: 'InvoiceToGeneralLedger',
    label: 'Invoice To General Ledger',
    licensedPerProperty: true
  },

  /**
   * A module that allows outlets to be configured for retail purposes and provides specialized
   * functionality for retail outlets.
   */
  RetailPos: {id: "RetailPos", label: "Retail POS", licensedPerProperty: true},

  /**
   * A module that allows a property to configure campaigns that can be used to reward customers
   * under certain conditions, over a period of time.
   */
  Campaigns: {id: "Campaigns", label: "Campaigns", licensedPerProperty: true},

  /**
   * Modules for accounting interfaces
   */
  SageAccountingInterface: {
    id: "SageAccountingInterface",
    label: "Sage Accounting Interface",
    licensedPerProperty: true
  },
  XeroAccountingInterface: {
    id: "XeroAccountingInterface",
    label: "Xero Accounting Interface",
    licensedPerProperty: true
  },

};

export default Module;
